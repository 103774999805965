import isString from "lodash/isString";

const normalizeUuid = uuid =>
  isString(uuid)
  ? uuid
    .trim()
    .replace(/-/g, '')
    .replace('\t', '')
  : uuid;

export default normalizeUuid;
