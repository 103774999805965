import React from 'react';

// import Example from './components/Example';
import Planogram from '@standard/planogram-component';
import normalizeUuid from './normalizeUuid';
import isString from 'lodash/isString';


// const [plano, setPlano] = useState({});
// const [cameraPosition, setCameraPosition] = useState([0, 0, 0]);
// const [sectionSelected, setSectionSelected] = useState(0);

class App extends React.Component {
  state = {
    planogram: null,
    position_x: -0.755205,
    position_y: 1.63608,
    position_z: 1.935864,
    widthPlanoView: 'auto',
    heightPlanoView: '100%'
  };

  componentDidMount = () => {
      let planogram = require("./planogram.json");
      const itemArray = planogram.items;
      const bagItems = itemArray.reduce((acc, obj) => {
        const uuidNormalized = normalizeUuid(obj.uuid);
        const imagePath = isString(uuidNormalized)
          ? `https://static-assets.nonstandard.ai/${uuidNormalized}.jpg`
          : '';
        const itemCopy = Object.assign({}, obj);
        itemCopy.imagePath = imagePath;
        acc[itemCopy.uuid] = itemCopy;
        return acc;
      }, {});

      planogram.itemsDict = bagItems;
      this.setState({
        planogram,
      });
  };
  changeX = (position_x) => {
    this.setState({
      position_x: position_x
    });
  };
  changeY = (position_y) => {
    this.setState({
      position_y: position_y
    });
  };
  changeZ = (position_z) => {
    this.setState({
      position_z: position_z
    });
  };
  toggleWidth = () => {
    this.setState(prevState => {
      return ({
        widthPlanoView: prevState.widthPlanoView === 'auto' ? '400px' : 'auto'
      });
    });
  };

  toggleHeight= () => {
    this.setState(prevState => {
      return ({
        heightPlanoView: prevState.heightPlanoView === '100%' ? '500px' : '100%'
      });
    });
  };

  render() {
    const { planogram: plano, position_x, position_y, position_z, widthPlanoView, heightPlanoView } = this.state;
    if (!plano) { return <h1>LOADING PLANOGRAM</h1>; }

    return (
      <div className="Demo" style={{ width: widthPlanoView, height: heightPlanoView}}>
        <input type="number" step="0.01" value={position_x} onChange={e => { this.changeX(e.target.value); }} />
        <input type="number" step="0.01" value={position_y} onChange={e => this.changeY(e.target.value)} />
        <input type="number" step="0.01" value={position_z} onChange={e => this.changeZ(e.target.value)} />
        <button type="button" onClick={this.toggleWidth}>Toggle between full and smaller width view</button>
        <button type="button" onClick={this.toggleHeight}>Toggle between full and smaller height view</button>


        <Planogram
          position={[position_x, position_y, position_z]}
          showPosition
          locations={plano.locations}
          itemsDict={plano.itemsDict}
          sections={plano.store_model.sections}
        />
      </div>
    );

  }
}

export default App;
// render(<App style={{ scroll: "no", style: "overflow: hidden" }} />, document.getElementById('root'));
