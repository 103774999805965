import React from 'react';
import logo from './logo.svg';
import './App.css';

import Plano from './planogram-demo'
function App() {
  return (
    <div className="App">
    <Plano />
    </div>
  );
}

export default App;
